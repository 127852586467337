<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Danh mục tin tức </a></li>
    </ol>
    <div class="container-fluid">
      <div class="col-sm-10">
        <div class="form-group">
          <router-link to="/admin/category/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới Danh mục tin tức
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="menu != null"></i> Quản lý
              Danh mục : ( {{ menu.length }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Danh mục cha</th>
                      <th>Danh mục con</th>
                      <th>Tuỳ chọn</th>
                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                        <br />

                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <template
                      v-for="(value, key) in paginationslice(menu, 10, page)"
                    >
                      <tr :Key="key">
                        <td>{{ key + 1 }}</td>
                        <td>
                          <a
                            :href="'/vn/' + value.slug"
                            target="_black"
                            class="text text-primary"
                          >
                            {{ value.name }}
                          </a>
                        </td>

                        <td>
                          <table class="table table-sm">
                            <thead v-if="getChild(value.id).length > 0">
                              <tr>
                                <th>STT</th>
                                <th>Danh mục</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(child, k) in getChild(value.id)"
                                :key="k"
                              >
                                <td>{{ k + 1 }}</td>
                                <td>
                                  <a
                                    :href="'/vn/' + child.slug"
                                    target="_black"
                                    class="text text-primary"
                                  >
                                    {{ child.name }}
                                  </a>
                                </td>

                                <td>
                                  <router-link
                                    class="btn btn-sm btn-success"
                                    v-bind:to="
                                      '/admin/category/edit/' + child.id
                                    "
                                  >
                                    <i class="fa fa-edit"></i>
                                    Sửa
                                  </router-link>

                                  <div
                                    class="btn btn-sm btn-danger click_remove"
                                    @click="removeElement(child.id)"
                                  >
                                    <i class="fa fa-remove"></i> Xoá
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td>
                          <router-link
                            class="btn btn-sm btn-success"
                            v-bind:to="'/admin/category/edit/' + value.id"
                          >
                            <i class="fa fa-edit"></i>
                            Sửa
                          </router-link>

                                <div v-if="getChild(value.id).length==0"
                                    class="btn btn-sm btn-danger click_remove"
                                    @click="removeElement(value.id)"
                                  >
                                    <i class="fa fa-remove"></i> Xoá
                                  </div>

                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>

                <nav v-if="menu != null && menu.length > 10">
                  <VuePagination
                    :page-count="Math.ceil(menu.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";
export default {
  name: "News.List",
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      menu: null,
      page: 1,
      id_load: "",

      my_url: window.my_url,
      is_detail: [],
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
  },

  created() {
    axios
      .get(window.my_api + "api/newscate/get-newscates", 
              {
                    headers: window.my_header
              }
      )
      .then((res) => {
        if (res.status == 200) {
          this.mydata = res.data.data;

          this.menu = this.mydata.filter((e) => e.parentId == null);

          this.menu.sort((a, b) => a.orderBy - b.orderBy);

          this.is_loading = false;
        } else {
          console.log("ERROR", res);
        }
      });
  },
  methods: {
    getChild(id) {
      if (this.mydata == null) {
        return new Array();
      }
      var result = this.mydata.filter((e) => e.parentId == id);

      result.sort((a, b) => a.orderBy - b.orderBy);

      return result;
    },
    changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    removeElement(id) {
      this.$swal
        .fire({
          title: "Bạn có muốn xóa?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.id_load = id;
            axios
              .delete(window.my_api + "api/newscate/remove-newscate", {
                  data: { newsCateIds: [id] },
                  headers: window.my_header
              })
              .then((res) => {
                if (res.status == 200) {
                  this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Xóa thành công.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.mydata = this.mydata.filter((el) => el.id !== id);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
        });
    },
  },
};
</script>






